import { BOOTH_BASE_FRAGMENT } from '@/graphql/_Fragments/Booth/Base';

export const GEOZONE_BASE_FRAGMENT = `
  fragment geozoneBaseFragment on Geozone {
    uid
    schemaCode
    id
    name
    shortName
    booth {
      ...boothBaseFragment
    }
  }
  ${BOOTH_BASE_FRAGMENT}
`;
